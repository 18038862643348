:root {
  --palette-text-secondary: #606a76;
  --palette-text-primary: #2b3949;
  --palette-text-tertiary: #838b95;
  --palette-text-link: #1c74ab;
  --palette-text-strokes: #dfe1e4;
  --palette-dark-gray: #606a76;
  --palette-medium-dark-gray: #838b95;
  --palette-link-color-hover: #035b92;

  --palette-status-unresolved: #1c74ab;
  --palette-status-resolved: #2b3949;

  --palette-accent-main: rgb(11, 102, 92);

  --palette-common-white: #ffffff;
  --palette-common-borderGray: #dfe1e4;

  --palette-strokes-divider: rgba(43, 57, 73, 0.15);
  --palette-strokes-tertiary: #838b95;

  --palette-app-background-main: #f3f6f8;
  --palette-app-background-lightestGray: #f7f7f8;
  --palette-app-background-lightestGrayHover: #eeeff0;
  --palette-app-background-bluePastel: #cad8e4;
  --palette-app-background-bluePastelAlt: #ebf6ff;
  --palette-app-background-navy: #4c5a73;
  --palette-app-background-lightblue: #cee4f6;

  --palette-primary-main: #f4b542;

  --palette-danger-main: #f8ecef;

  --palette-secondary-main: #1c74ab;

  --palette-success-dark: #1b5e20;

  --palette-error-dark: #b71c1c;

  --palette-logo-main: #e0aa5a;

  --typography-h1-font-size: 2.5rem;
  --typography-h1-line-height: 3.25rem;

  --typography-h1-mobile-font-size: 2rem;
  --typography-h1-mobile-line-height: 2.6rem;

  --typography-h2-font-size: 2.08rem;
  --typography-h2-line-height: 2.7rem;

  --typography-h2-mobile-font-size: 1.71rem;
  --typography-h2-mobile-line-height: 2.23rem;

  --typography-h3-font-size: 1.73rem;
  --typography-h3-line-height: 2.25rem;

  --typography-h3-mobile-font-size: 1.475rem;
  --typography-h3-mobile-line-height: 1.92rem;

  --typography-h4-font-size: 1.44rem;
  --typography-h4-line-height: 1.875rem;

  --typography-h4-mobile-font-size: 1.269rem;
  --typography-h4-mobile-line-height: 1.649rem;

  --typography-h5-font-size: 1.18rem;
  --typography-h5-line-height: 1.562rem;

  --typography-h5-mobile-font-size: 1.09rem;
  --typography-h5-mobile-line-height: 1.422rem;

  --typography-h6-font-size: 0.9375rem;
  --typography-h6-line-height: 1.1875rem;

  --typography-lead-font-size: 1.2rem;
  --typography-lead-line-height: 1.5625rem;

  --typography-lead-mobile-font-size: 1.09375rem;
  --typography-lead-mobile-line-height: 1.422rem;

  --typography-subtitle1-font-size: 16px;
  --typography-subtitle1-line-height: 25px;

  --typography-subtitle2-font-size: 14px;
  --typography-subtitle2-line-height: 21px;

  --typography-body1-font-size: 1rem;
  --typography-body1-line-height: 1.3rem;

  --typography-body1-mobile-font-size: 0.875rem;
  --typography-body1-mobile-line-height: 1.1375rem;

  --typography-body2-font-size: 0.83125rem;
  --typography-body2-line-height: 1.3rem;

  --typography-body2-mobile-font-size: 0.73rem;
  --typography-body2-mobile-line-height: 1.0625rem;

  --typography-caption-font-size: 0.69375rem;
  --typography-caption-line-height: 0.902rem;

  --typography-mini-caption-font-size: 0.575rem;
  --typography-mini-caption-line-height: 0.75rem;

  --typography-overline-font-size: 0.6875rem;
  --typography-overline-line-height: 0.8125rem;
}

html {
  margin: 0;
  background-color: var(--palette-common-white);
  font-family: Agipo, Libre Franklin, sans-serif;
  font-size: 16px;
  letter-spacing: 0.25px;
}

body {
  margin: 0;
  background-color: var(--palette-common-white);
  font-family: Agipo, Libre Franklin, sans-serif;
  font-size: 16px;
  overflow: hidden;
}

a {
  color: var(--palette-text-link);
}

a:hover {
  color: var(--palette-link-color-hover);
  text-decoration: inherit;
}

.fontWeightBold {
  font-weight: bold;
}

.typo-body2 {
  font-size: var(--typography-body2-font-size);
  line-height: var(--typography-body2-line-height);
}

@media (max-width: 768px) {
  .typography-small-body {
    font-size: 14px;
    line-height: 18px;
  }

  .typo-body2 {
    font-size: var(--typography-body2-mobile-font-size);
    line-height: var(--typography-body2-mobile-line-height);
  }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}